import React from "react";
import Footer from "../../../components/Footer/Footer";
import CategoryLeftNav from "../../../components/TwoColumnLeftNav/CategoryLeftNav";
import {graphql} from "gatsby";
import Pagination from "../../../components/Pagination/Pagination";
import useTranslationsCat from "../../../components/useTranslationsCat";

const SeriesProducts = ({ data: { allMdx } }) => {
    const { seriesProducts } = useTranslationsCat()
    return (
        <>
            <article className="category-page two-column-left-nav">
                <CategoryLeftNav />
                <section className="main-content">
                    <section className="main-content">
                        <div className="content-container">
                            <h2 className="large bold">{ seriesProducts }</h2>
                            <Pagination posts={allMdx.edges} />
                        </div>
                    </section>
                </section>
            </article>
            <Footer />
        </>
    );
};

export default SeriesProducts;

export const query = graphql`
	query SeriesProducts($locale: String!) {
		allMdx(
			filter: {
				fileAbsolutePath: { regex: "/(product)/.*\\\\.mdx$/" }
				frontmatter: {  ignoreSearch: { ne: "true"}, categories: { in: [
				"series_products",
				"series_sk270wp",
				"series_sk1260",
				"series_sk110trhb",
				"series_sk630ph",
				"series_sk660ph",
				"series_sk960a",
				"series_bms90s",
				"series_bmt90s",
				"series_bmt75s",
				"series_bms75p",
				"series_bmt75p",
				"series_bms100p",
				"series_bmt100p",
				"series_protector_pipe_for_bimetal",
				"series_protector_pipe_for_remote_sensing",
				"series_lb100s_150s",
				"series_vb100p",
				"series_young_anemometer",
				"series_handheld_anemometer",
				"series_wvat6d0",
				"series_wat6d0",
				"series_ra001",
				"series_indicator_temp",
				"series_indicator_hum",
				"series_skrsd10",
				"series_skrm10c",
				"series_skrm18j",
				"series_surface_bimetal",
				"series_pocket_bimetal",
				"series_asphalt_bimetal",
				"series_miluno",
				"series_milunotz",
				"series_woodenz_base",
				"series_woodenz_base2",
				"series_soil_air",
				"series_checkermate",
				"series_sigma2_hvr_charts",
				"series_sigmamini_charts",
				"series_sigma2_other_accessories",
				"series_skrhg_option",
				"series_calibration_solution",
				"series_sensor_filters",
				"series_tm24p",
				"series_babymate",
				] } }
				fields: { locale: { eq: $locale } }
			}
			sort: { fields: [frontmatter___sku], order: ASC }
		) {
			edges {
				node {
					frontmatter {
						sku
						name
						model
						price
						taxIncludedPrice
						productImages
					}
					fields {
						locale
					}
					parent {
						... on File {
							relativeDirectory
						}
					}
				}
			}
		}
	}
`;
